// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Update the import statement
import { Lucid, Blockfrost } from 'lucid-cardano';
import Cookies from 'js-cookie';
import { WEBSITE } from './Constants';

import './App.css';

// Use the Lucid object in your component


import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Trading from './pages/Trade';
import MyOrders from './pages/MyOrders';
import RSO from './pages/RSO';
import MessageWindow from './components/MessageWindow';
import tokenALogo from './assets/images/tokenA.png';
import tokenBLogo from './assets/images/tokenB.png';

let lucid;
switch(WEBSITE){
  case 'https://test-orderbook.adalink.io':
    lucid = await Lucid.new(
      new Blockfrost("https://cardano-preview.blockfrost.io/api/v0", "preview7iVl38anG9Np8lT4JzXCKB16mxPC8Kyg"),
      "Preview",
    );
  break;
  case 'https://orderbook.adalink.io':
    lucid = await Lucid.new(
      new Blockfrost("https://cardano-mainnet.blockfrost.io/api/v0", "mainnetedOr1A0jt3OG6NJ4dI0U59cFb42hgD3t"),
      "Mainnet",
    );
  break;
}





//allPairs = [{name:"TokenA-tADA",policy_id:"ccc33d8b674ebeff713436985c513e80ee3c3eecfab11378ea144dd8",token_name:"TokenA",decimals:6,icon:tokenALogo}
//                  , {name:"TokenB-tADA",policy_id:"6a25d74d073fc021944ddc109b8d61a77dc636133e2ed295ff59ae3e",token_name:"TokenB",decimals:0,icon:tokenBLogo}];

let checkForLastConnectedWalletEnabled = true;
let connectedWalletAPI;


function App() {
  const [allPairs,setAllPairs] = useState();
  const [selectedPair, setSelectedPair] = useState(); // State for selected pair
  const [orders, setOrders] = useState(); // State for all orders
  const [myOrders,setMyOrders] = useState();
  const [buyOrders,setBuyOrders] = useState([]);
  const [sellOrders,setSellOrders] = useState([]);
  const [walletAPI,setWalletAPI]=useState();
  const [walletName,setWalletName]=useState();
  
  const [showMessageWindow,setShowMessageWindow] = useState(false);
  const [messageWindowContent,setMessageWindowContent] = useState('');
  const [messageWindowButtonText,setMessageWindowButtonText] = useState('Ok');
  const [messageWindowOnAction,setMessageWindowOnAction] = useState();

  const [bidPrice,setBidPrice] = useState('N/A');
  const [askPrice,setAskPrice] = useState('N/A');
  const [spread,setSpread] = useState('N/A');
  
  function updateSelectedTokenPrice(buyOrders,sellOrders){
    //start with buy orders
    if(buyOrders.length>0)
      setBidPrice(parseFloat((parseFloat(buyOrders[0].price)*Math.pow(10,selectedPair.decimals-6)).toFixed(6))+' '+'ADA/'+selectedPair.token_name);
    else
      setBidPrice('N/A');
    //sell orders
    if(sellOrders.length>0)
      setAskPrice(parseFloat((parseFloat(sellOrders[0].price)*Math.pow(10,selectedPair.decimals-6)).toFixed(6))+' '+'ADA/'+selectedPair.token_name);
    else
      setAskPrice('N/A');
    if(buyOrders.length>0 && sellOrders.length>0)
      setSpread(parseFloat((parseFloat(sellOrders[0].price)-parseFloat(buyOrders[0].price)).toFixed(6))+' ADA/'+selectedPair.token_name);
    else
      setSpread('');

      
  }

  async function initalizeMyOrders(walletAPI,orders){
    
    let myOrders = [];
    if (walletAPI != undefined){
      lucid.selectWallet(walletAPI);
      let address = await lucid.wallet.address();
      let publicKeyHash = lucid.utils.getAddressDetails(address).paymentCredential?.hash;
        myOrders = orders.filter((order) => order.bidder == publicKeyHash);
        return myOrders;  
    }else{
      return [];
    }
  }

  useEffect(() => {
    // Your code here

    const intervalId = setInterval(() => {
      // This code will run every 5 seconds
      fetch(WEBSITE+`/api/get-order-list.php?pair=${selectedPair}`,{cache:'reload'})
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
          // Filter buy and sell orders based on the "call" property
          setOrders(data);
          
          const buyOrders = data.filter((order) => (order.call === 'buy' && order.token_name == selectedPair.token_name && order.policy_id == selectedPair.policy_id));
          const sellOrders = data.filter((order) => (order.call === 'sell' && order.token_name == selectedPair.token_name && order.policy_id == selectedPair.policy_id));
          // Update the buy and sell orders
          const sortedBuyOrders = buyOrders.sort((a,b) => parseFloat(b.price) - parseFloat(a.price));
          const sortedSellOrders = sellOrders.sort((a,b) => parseFloat(a.price) - parseFloat(b.price));
          setBuyOrders(sortedBuyOrders);
          setSellOrders(sortedSellOrders);
          updateSelectedTokenPrice(sortedBuyOrders,sortedSellOrders);
      })
      .catch((error) => {
        console.error('Error fetching orders:', error);
      });
    }, 5000);

    // Don't forget to clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [selectedPair]); // Empty dependency array to run the effect only once

  useEffect(() => {
    // Fetch orders when selectedPair changes
    if (selectedPair) {
      
      fetch(WEBSITE+`/api/get-order-list.php?pair=${selectedPair}`,{cache:'reload'})
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          // Filter buy and sell orders based on the "call" property
          setOrders(data);
          console.log(data)
          const buyOrders = data.filter((order) => (order.call === 'buy' && order.token_name == selectedPair.token_name && order.policy_id == selectedPair.policy_id));
          const sellOrders = data.filter((order) => (order.call === 'sell' && order.token_name == selectedPair.token_name && order.policy_id == selectedPair.policy_id));
          // Update the buy and sell orders
          const sortedBuyOrders = buyOrders.sort((a,b) => parseFloat(b.price) - parseFloat(a.price));
          const sortedSellOrders = sellOrders.sort((a,b) => parseFloat(a.price) - parseFloat(b.price));
          setBuyOrders(sortedBuyOrders);
          setSellOrders(sortedSellOrders);
          updateSelectedTokenPrice(sortedBuyOrders,sortedSellOrders);
          if(walletAPI == undefined)
            initalizeMyOrders(connectedWalletAPI,data)
            .then((myOrders) => setMyOrders(myOrders))
        })
        .catch((error) => {
          console.error('Error fetching orders:', error);
        });
        
    }
    
  }, [selectedPair]);

  useEffect(() => {
    //this will run once!
    if (checkForLastConnectedWalletEnabled) {
      connectLastConnectedWallet();
      checkForLastConnectedWalletEnabled=false
    }
    switch (WEBSITE){
      case 'https://orderbook.adalink.io':
        fetch('https://orderbook.adalink.io/api/verified-pairs.json',{cache:'reload'})
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setAllPairs(data);
          setSelectedPair(data[0])
        })
      break;
      case 'https://test-orderbook.adalink.io':
        let _allPairs = [{name:"TokenA-tADA",policy_id:"ccc33d8b674ebeff713436985c513e80ee3c3eecfab11378ea144dd8",token_name:"TokenA",decimals:6,icon:tokenALogo}
        , {name:"TokenB-tADA",policy_id:"6a25d74d073fc021944ddc109b8d61a77dc636133e2ed295ff59ae3e",token_name:"TokenB",decimals:0,icon:tokenBLogo}];
        setAllPairs(_allPairs)
        setSelectedPair(_allPairs[0])
        break;
    }
    
  },[])

  // Function to handle pair selection
  const handlePairSelect = (pair) => {
    setSelectedPair(pair);
  };

  async function connectLastConnectedWallet(){
    if(Cookies.get('lastConnectedWalletName')){
      //console.log('checking last connected wallet...');
      let lastConnectedWalletName = Cookies.get('lastConnectedWalletName');
      let lastConnectedWalletAPI;
      switch(lastConnectedWalletName){
        case 'nami':
          lastConnectedWalletAPI = await window.cardano.nami.enable();
          setWalletName('Nami');
        break;
        case 'eternl':
          lastConnectedWalletAPI = await window.cardano.eternl.enable();
          setWalletName('Eternl');
        break;
        case 'flint':
          lastConnectedWalletAPI = await window.cardano.flint.enable();
          setWalletName('Flint');
        break;
      }
      
      setWalletAPI(lastConnectedWalletAPI);
      //console.log(Cookies.get('lastConnectedWalletName'))
      //console.log(walletName)
      connectedWalletAPI=lastConnectedWalletAPI;
      Cookies.set('lastConnectedWalletName',lastConnectedWalletName,{expires:1000});
    }
  }


  return (
    <Router>
      <div className="App">
        <Header walletAPI={walletAPI} setWalletAPI={setWalletAPI} connectedWalletAPI={connectedWalletAPI} walletName={walletName} setWalletName={setWalletName} orders={orders} setMyOrders={setMyOrders} initalizeMyOrders={initalizeMyOrders} Cookies={Cookies}/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/trading"
            element={(
              <Trading
                buyOrders={buyOrders}
                sellOrders={sellOrders}
                tokenPrices={{bidPrice:bidPrice,askPrice:askPrice,spread:spread}}
                onPairSelect={handlePairSelect}
                allPairs={allPairs}
                selectedPair={selectedPair}
                lucid={lucid}
                walletAPI={walletAPI}
                setMessageWindowContent={setMessageWindowContent}
                setMessageWindowButtonText={setMessageWindowButtonText}
                setShowMessageWindow={setShowMessageWindow}  
              />
            )}
          />
          <Route path="/my-orders" 
            element={
              <MyOrders 
                orders={orders} 
                myOrders={myOrders}
                setMyOrders={setMyOrders}
                allPairs={allPairs} 
                lucid={lucid} 
                walletAPI={walletAPI} 
                setMessageWindowContent={setMessageWindowContent}
                setMessageWindowButtonText={setMessageWindowButtonText}
                setShowMessageWindow={setShowMessageWindow}  
              />} />
          <Route path="/revenue-share-offering"
            element={
              <RSO 
                lucid={lucid}
                walletAPI={walletAPI}
                setMessageWindowContent={setMessageWindowContent}
                setMessageWindowButtonText={setMessageWindowButtonText}
                setShowMessageWindow={setShowMessageWindow}
              />} />
        </Routes>
        {showMessageWindow && ( // Show OrderSummary when orderSummaryVisible is true
          <MessageWindow
            message={messageWindowContent}
            buttonText={messageWindowButtonText}
            onClose={() => setShowMessageWindow(false)} // Close OrderSummary
            onAction={ () => setShowMessageWindow(false)} // Pass placeOrderHandler as onPlaceOrder
          />
        )}
        <Footer />
      </div>
    </Router>
  );
}

export default App;

